*{
    font-family: 'Raleway', sans-serif; 
}

.team-container{
    display: inherit;
    justify-content:center;
    align-items: center; 
    position: relative; 
}

.team-container-card{
    margin-top: 2rem ;
    margin-bottom: 2rem;
}

.team-heading1{
   text-align: center;
   margin-top: 2rem;
}

.team-subheading1{
    opacity: 0.7;
    text-align: center;
    font-size: 18px;
}

@media (max-width:991px){
    .team-container-card{
        margin-top: 1rem ;
    }
}
@media (max-width:767px){
    .team-container-card{
        margin-top: 1rem ;
    }
}
@media (max-width:480px){
    .team-container-card{
        margin-top: 1rem ;
    }
}
@media (max-width:400px){
    .team-container-card{
        margin-top: 1rem ;
    }
}
@media (min-width:300px) and (max-width:400px){
 .team-container-card{
    margin-top: 1rem ;
}
}