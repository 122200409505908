:root{
    --primary: #3acbf7;
}

.NavbarItems{
    background: -webkit-linear-gradient(bottom, rgb(192, 250, 252) 0%, rgb(227, 251, 253) 100%);
    background: -o-linear-gradient(bottom, rgb(192, 250, 252) 0%, rgb(227, 251, 253) 100%);
    background: -ms-linear-gradient(bottom, rgb(192, 250, 252) 0%, rgb(227, 251, 253) 100%);
    background: -moz-linear-gradient(bottom, rgb(192, 250, 252) 0%, rgb(227, 251, 253) 100%);
    background: linear-gradient(to top, rgb(192, 250, 252) 0%, rgb(227, 251, 253) 100%);
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo{
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}
ol, ul{
    padding-left: 0;
}
.fa-react{
    margin-left: 0.5rem;
    font-size: 1.6rem;
}


.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
    margin-top: 1rem;
}

.nav-links{
    color: black;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links-button{
    padding: 12px 20px;
    border-radius: 4px;
    font-size: 18px;
    color: #fff;
    background-color: var(--primary);
    text-decoration: none;
    margin-left: 2rem !important;
}

.nav-links-button:hover{
    color: #000;
    background-color: rgb(102, 204, 204);
    text-decoration: none;
}

.nav-links:hover{
    background-color: rgb(170, 240, 240);
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

a:hover {
    color: #000000;
}

.fa-bars {
    color: black;
}

.fa-times:before{
    color: black;
}

.nav-links-mobile{
    display: none;
}

.menu-icon{
    display: none;
}

@media screen and (max-width: 960px){
    .NavbarItems{
        position: relative;
    }

    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 80px);
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        margin-top: 0em;
        margin-bottom: 0px;
        transition: all 0.5s ease;
    }

    .nav-menu.active{
        background: -webkit-linear-gradient(bottom, rgb(192, 250, 252) 0%, rgb(227, 251, 253) 100%);
        background: -o-linear-gradient(bottom, rgb(192, 250, 252) 0%, rgb(227, 251, 253) 100%);
        background: -ms-linear-gradient(bottom, rgb(192, 250, 252) 0%, rgb(227, 251, 253) 100%);
        background: -moz-linear-gradient(bottom, rgb(192, 250, 252) 0%, rgb(227, 251, 253) 100%);
        background: linear-gradient(to top, rgb(192, 250, 252) 0%, rgb(227, 251, 253) 100%);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        height: 90vh;
    }

    .nav-links{
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover{
        background-color: #86a0a1;
        border-radius: 0;
    }

    .nav-links-button{
        padding: 12px 20px;
        border-radius: 4px;
        font-size: 18px;
        color: #fff;
        background-color: var(--primary);
        text-decoration: none;
        margin-left: 0rem !important;
    }

    .navbar-logo{
        position: absolute;
        top: 0;
        left: 0;
    }

    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times{
        color: white;
        font-size: 2rem;
    }

    .nav-links-mobile{
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #4ad9e4;
        text-decoration: none;
        color: white;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover{
        background: white;
        color: #86a0a1;
        transition: 250ms;
    }

    Button{
        display: none;
    }
}