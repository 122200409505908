*{
    font-family: 'Raleway', sans-serif; 
}
.btncolor{
    background-color: #3884FD;
    margin-top: 2rem;
    
}
.home-container{
    display: inherit;
    justify-content:space-around;
    align-items: center;
    position: relative;
    padding-bottom: 2.5rem;
}

.background-image{
    position: relative;
}

.background-image:before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../assets/imgs/background.png);
    opacity: 0.8;
}

.main-heading{
    font-size: 4em;
    margin-top: 10rem;
}

.text{
    font-family: 'Raleway', sans-serif; 
    font-weight: 500;
    color: white;
    font-size: 1.2rem;
}

.lottie1{
    height: 300 !important;
    width: auto !important;
}
@media (min-width: 991px) and (max-width: 1300px) 
{
    .text{
        font-family: 'Raleway', sans-serif; 
        font-weight: 500;
        color: white;
        font-size: 1rem;
    }

    .btncolor{
        background-color: #3acbf7;
        margin-top: 3em;
    }
    .main-heading{
        font-family: 'Raleway', sans-serif; 
        font-size: 3em;
    }

    .background-image:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(../assets/imgs/background6.png);
        opacity: 0.8;
    }
}
@media (max-width: 991px) {
    .text{
        font-family: 'Raleway', sans-serif; 
        font-weight: 500;
        color: white;
        font-size: 1rem;
    }

    .btncolor{
        background-color: #3acbf7;
        margin-top: 3em;
    }
    .main-heading{
        font-family: 'Raleway', sans-serif; 
        font-size: 3em;
    }

    .background-image:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(../assets/imgs/background3.png);
        opacity: 0.8;
    }
}

@media (min-width: 575px) and (max-width: 768px) {
    .text{
        font-family: 'Raleway', sans-serif; 
        font-weight: 500;
        color: white;
        font-size: 1rem;
    }

    .btncolor{
        background-color: #3acbf7;
        padding: 0.5em;
    }
    .main-heading{
        font-family: 'Raleway', sans-serif; 
        font-size: 3em;
    }
    .background-image:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(../assets/imgs/background4.png);
        opacity: 0.8;
    }
}
@media (min-width: 480px) and (max-width: 575px) {
    .text{
        font-family: 'Raleway', sans-serif; 
        font-weight: 500;
        color: white;
        font-size: 1rem;
    }

    .btncolor{
        background-color: #3acbf7;
        margin-top: 0px;
    }
    .main-heading{
        font-family: 'Raleway', sans-serif; 
        font-size: 3em;
        margin-top: 13rem;
    }
    .background-image:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(../assets/imgs/background2.png);
        opacity: 0.8;
    }
}

@media (max-width: 480px) {
    .text{
        font-family: 'Raleway', sans-serif; 
        font-weight: 500;
        color: white;
        font-size: 1rem;
    }

    .btncolor{
        background-color: #3acbf7;
        margin-top: 0px;
    }
    .main-heading{
        font-family: 'Raleway', sans-serif; 
        font-size: 3em;
    }
    .background-image:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(../assets/imgs/background2.png);
        opacity: 0.8;
    }
}


@media (min-width: 300px) and (max-width: 400px){
    .text{
        font-family: 'Raleway', sans-serif; 
        font-weight: 500;
        color: white;
        font-size: 1rem;
    }

    .btncolor{
        background-color: #3acbf7;
        margin-top: 0px;
    }
    .main-heading{
        font-family: 'Raleway', sans-serif; 
        font-size: 3em;
        margin-top: 1em;
    }
    .background-image:before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(../assets/imgs/background2.png);
        opacity: 0.8;
    }
}