.container-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #EDEFF2;
}

.footer-c{
    padding-top: 1.5rem;
}

.footer-paragraph {
    color: rgb(106, 133, 156);
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
}

h5{
    color: rgb(103, 103, 112);
    font-size: 18px;
    line-height: 20px;
    font-weight: 300;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.social-icons{
    display: flex;
    flex-direction: column;
}

a{
    text-decoration: none;
}

.span-style{
    margin-bottom: 6px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(213, 213, 224);
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 300;
}

.span-style:hover{
    margin-bottom: 6px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(213, 213, 224);
    color: rgb(102, 140, 173);
 
}


@media only screen and (max-width: 680px){
    .container-footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:991px){
    .footer-paragraph{
        padding: 0 0.7rem 0 0.7rem;
    }

    h5{
        color: rgb(103, 103, 112);
        font-size: 18px;
        line-height: 20px;
        font-weight: 300;
        letter-spacing: 4px;
        text-transform: uppercase;
        padding: 0.5rem;
    }
}
@media (max-width:767px){
    .footer-paragraph{
        padding: 0 0.7rem 0 0.7rem;
    }

    h5{
        color: rgb(103, 103, 112);
        font-size: 18px;
        line-height: 20px;
        font-weight: 300;
        letter-spacing: 4px;
        text-transform: uppercase;
        padding: 0.5rem;
    }
}
@media (max-width:480px){
    .footer-paragraph{
        padding: 0 0.7rem 0 0.7rem;
    }

    h5{
        color: rgb(103, 103, 112);
        font-size: 18px;
        line-height: 20px;
        font-weight: 300;
        letter-spacing: 4px;
        text-transform: uppercase;
        padding: 0.5rem;
    }
}
@media (max-width:400px){
    .footer-paragraph{
        padding: 0 0.7rem 0 0.7rem;
    }

    h5{
        color: rgb(103, 103, 112);
        font-size: 18px;
        line-height: 20px;
        font-weight: 300;
        letter-spacing: 4px;
        text-transform: uppercase;
        padding: 0.5rem;
    }
}
@media (min-width:300px) and (max-width:400px){
    .footer-paragraph{
        padding: 0 0.7rem 0 0.7rem;
    }

    h5{
        color: rgb(103, 103, 112);
        font-size: 18px;
        line-height: 20px;
        font-weight: 300;
        letter-spacing: 4px;
        text-transform: uppercase;
        padding: 0.5rem;
    }

}